import { GET_REVIEW_STATE } from '../../constants/ActionTypes';

export default function getReviewState(url) {
  return {
    type: GET_REVIEW_STATE,
    request: {
      op: 'get',
      path: `${url}/@workflow?metadata_fields=state`,
    },
  };
}
