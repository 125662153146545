import React, {useCallback, useEffect, useRef, useState} from 'react';
import raw from '../../actions/raw/raw';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {getCurrentStateMapping} from '@plone/volto/helpers/Workflows/Workflows';
import {flattenToAppURL} from '@plone/volto/helpers/Url/Url';
import {toast} from 'react-toastify';
import Toast from '@plone/volto/components/manage/Toast/Toast';
import ModalForm from './ModalForm';
import cx from 'classnames';
import getReviewState from '../../actions/review_state/review_state';
import {
  getContent,
  getWorkflow,
} from '@plone/volto/actions';

const ContentStatusHistory = (props) => {
  let { content, pathname } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [currentState, setCurrentState] = useState({});
  const handleSubmit = useCallback(async () => {
    let transitionValue = document?.getElementById('transitionValue')
      ?.innerHTML;
    await dispatch(raw(transitionValue, comment, false));
    await setOpen(false);
    const response = await dispatch(getReviewState(pathname));
    const submitted_state = getCurrentStateMapping(response?.state);
    toast.success(
      <Toast
        success
        title={`Workflow updated to: ${submitted_state?.label}`}
        content=""
      />,
    );
    setCurrentState(submitted_state);
    dispatch(getWorkflow(pathname));
    dispatch(getContent(pathname));
  }, [comment, dispatch, pathname]);
  const stateRef = useRef(content?.review_state);
  useEffect(() => {
    if (content.review_state) {
      stateRef.current = getCurrentStateMapping({
        [content?.review_state]: content?.review_state,
      });
      setCurrentState(stateRef.current);
    }
    setCurrentState(currentState);
  }, [content.review_state, currentState]);
  return (
      <ModalForm
        title="Change State"
        onSubmit={handleSubmit}
        onCancel={() => setOpen(false)}
        className={cx({
          [currentState?.value]: currentState?.value,
        })}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        currentState={currentState?.label}
        pathname={pathname}
        comment={comment}
        setComment={(event) => {
          event.preventDefault();
          setComment(event?.target?.value);
        }}
        trigger={
              <Link
                title="Change State"
                className={cx({
                  [currentState?.value]: currentState?.value,
                })}
                to={flattenToAppURL(`${pathname}/content_status_history`)}
                onClick={(e) => {
                  e.preventDefault();
                  return false;
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                  height="30px"
                  width="auto"
                  fill="currentcolor"
                  className="icon semaphore"
                >
                  <title>Change State</title>
                  <g fillRule="evenodd">
                    <path d="M18 11C17.449 11 17 10.552 17 10 17 9.448 17.449 9 18 9 18.551 9 19 9.448 19 10 19 10.552 18.551 11 18 11M18 7C16.346 7 15 8.346 15 10 15 11.654 16.346 13 18 13 19.654 13 21 11.654 21 10 21 8.346 19.654 7 18 7M18 19C17.449 19 17 18.552 17 18 17 17.448 17.449 17 18 17 18.551 17 19 17.448 19 18 19 18.552 18.551 19 18 19M18 15C16.346 15 15 16.346 15 18 15 19.654 16.346 21 18 21 19.654 21 21 19.654 21 18 21 16.346 19.654 15 18 15" />
                    <path d="M23,22 C23,22.552 22.551,23 22,23 L14,23 C13.449,23 13,22.552 13,22 L13,6 C13,5.448 13.449,5 14,5 L22,5 C22.551,5 23,5.448 23,6 L23,22 Z M27,11 L27,9 L25,9 L25,6 C25,4.346 23.654,3 22,3 L14,3 C12.346,3 11,4.346 11,6 L11,9 L9,9 L9,11 L11,11 L11,17 L9,17 L9,19 L11,19 L11,22 C11,23.654 12.346,25 14,25 L17,25 L17,31 L13,31 L13,33 L23,33 L23,31 L19,31 L19,25 L22,25 C23.654,25 25,23.654 25,22 L25,19 L27,19 L27,17 L25,17 L25,11 L27,11 Z" />
                  </g>
                </svg> Change State
              </Link>
        }
      />
  );
};
export default ContentStatusHistory;
